<template>
    <div class="box">
        <Header type="expert" :logo="logoUrl" :navList="navList" :typeList="[]" :navCurrentId="navCurrentId">
            <div slot="search" style="display: none"></div>
        </Header>

        <div class="content">
            <div class="title">
                {{ pageData.info.c_name }}
            </div>
            <div class="sub-title">
                {{ pageData.info.b_name }}
            </div>

            <div class="list-items">
                <div class="item" v-for="(item,index) in pageData.data">
                    <div class="main-header flex y-center x-left">
                        <span class="strong">{{ item.name }}</span>
                        <div class="color999 fs12 more" @click="handleToNextPage(item)">
                            更多 <i class="med med-right"></i>
                        </div>
                    </div>
                    <div class="item-name-list" v-if="item.isShowAll">
                        <div class="line flex x-left y-top" v-for="(data,idx) in item.data">
                            <div class="key">{{ idx }}</div>
                            <span class="strong">:</span>
                            <div class="name flex x-left flex-wrap">
                                <div :class="['n-i',pageData.info.c_name.indexOf('新疆') > -1 ? 'lang': '']" v-for="(name,i) in data" :key="i" @click="handleToPersonPage(name.UserID)">{{ name.real_name }}</div>
                            </div>
                        </div>
                    </div>
                    <div :class="['show-more-btn',item.isShowAll ? 'rotate' : '']" @click="handleChangeItem(index,item.isShowAll)">
                        <i :class="['med', 'med-zhankai']"></i>
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import {exportLogo , expertNavList } from "@/config/const";
import Header from '@/components/layout/layout-header';
import Footer from "@/components/layout/layout-footer.vue";
export default {
    name: "surnameList",
    components : {
        Header , Footer
    },
    data(){
        return {
            logoUrl : exportLogo ,
            navList : expertNavList,
            navCurrentId : 5,
            pageData: {
                info : {
                    c_name : '',
                    b_name : ''
                },
                data : []
            },
        }
    },
    created() {
        this.queryData = this.$route.query;
        this.getDataList()
    },
    methods : {
        getDataList(){
            this.request.post('BranchDetailsPc',{branch_id : this.queryData.id}).then(res => {
                console.log(res)
                res.data.forEach((item,index) => {
                    if(index == 0){
                        item.isShowAll = true;
                    }else{
                        item.isShowAll = false;
                    }
                    // 截取item.data 对象中的前三项，不全部展示
                    /*let data = {};
                    let keys = Object.keys(item.data);
                    keys.forEach((key,idx) => {
                        if(idx < 3){
                            data[key] = item.data[key];
                        }
                    })
                    item.data = data;*/
                })
                this.pageData = res;
            })
        },
        handleToNextPage(data){
            let { CouncilID , branch_id : BranchId , PeriodID, name } = data;
            this.$router.push({
                path : '/job-warehouse/rollCall',
                query : {
                    CouncilID,
                    BranchId,
                    PeriodID,
                    name
                }
            })
        },
        handleToPersonPage(personId){
            this.$router.push({
                path: '/person-detail',
                query: {
                    uid : personId
                }
            })
        },
        handleChangeItem(index,status){
            this.pageData.data[index].isShowAll = !status;
        }
    }
}
</script>

<style scoped lang="less">
    .content{
        width: 1240px;
        margin: 0 auto;
        min-height: 500px;
        .title{
            color: #333333;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }
        .sub-title{
            margin-top: 10px;
            color: #666666;
            font-size: 14px;
            text-align: center;
        }
        .list-items{
            margin-top: 30px;
            .item{
                width: 1200px;
                border-radius: 10px;
                margin: 0 auto 30px;
                background: #FFFFFF;
                box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.1);
                overflow: hidden;
                .main-header{
                    width: 100%;
                    height: 57px;
                    background: #1E51C9;
                    padding: 0 30px;
                    span{
                        font-weight: bold;
                        color: #FFFFFF;
                        font-size: 18px;
                    }
                    div{
                        cursor: pointer;
                        margin-left: auto;
                        color: #FFFFFF;
                    }
                }
                .item-name-list{
                    padding: 20px 30px;
                    .line{
                        margin-bottom: 10px;
                        .key{
                            flex-shrink: 0;
                            font-size: 14px;
                            font-weight: bold;
                            width: 90px;
                            text-align: justify;
                            text-align-last: justify;
                            text-justify: distribute-all-lines;
                        }
                        span{
                            margin-left: 6px;
                        }
                        .name{
                            .n-i{
                                min-width: 64px;
                                text-align: justify;
                                text-align-last: justify;
                                text-justify: distribute-all-lines;
                                cursor: pointer;
                                padding: 0 10px;
                                margin-bottom: 6px;
                            }
                            .lang{
                                /*background: #f8fbfd;
                                border-radius: 6px;
                                border: 1px solid #edeef4;
                                margin-left: 10px;*/
                                text-align: left;
                                min-width: max-content;
                            }
                        }
                    }
                }
                .show-more-btn{
                    padding: 10px;
                    cursor: pointer;
                    &.rotate{
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
</style>